import axios from "axios";

function initialState() {
    return {
        comments: [],
    };
}

export default {
    namespaced: true,
    state: initialState,

    getters: {
        comments: (state) => state.comments,
    },

    mutations: {
        UPDATE_COMMENTS(state, val) {
            state.segments = val;
        },
    },

    actions: {
        searchComment(ctx, form) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/api/Comment/Search", form)
                    .then((response) => resolve(response))
                    .catch((error) => reject(error.response));
            });
        },
        saveComment(ctx, form) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/api/Comment/Save", form)
                    .then((response) => resolve(response))
                    .catch((error) => reject(error.response));
            });
        },
        readComment(ctx, form) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/api/Comment/Read/Save", form)
                    .then((response) => resolve(response))
                    .catch((error) => reject(error.response));
            });
        },
    },
};
