import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

import {
    faAddressBook,
    faBuilding,
    faClock,
    faEye,
    faEyeSlash,
} from "@fortawesome/free-regular-svg-icons";
import {
    faWhatsapp,
    faSquareWhatsapp,
} from "@fortawesome/free-brands-svg-icons";

import {
    faFileInvoiceDollar,
    faPercent,
    faCopy,
    faFloppyDisk,
    faSpinner,
    faCalendarDays,
    faPen,
    faQrcode,
    faCircleInfo,
    faPeopleArrows,
    faArrowsUpDown,
    faArrowUpWideShort,
    faArrowDownWideShort,
    faFingerprint,
    faKey,
    faUnlock,
    faCalendarCheck,
    faCalendarXmark,
    faCircleCheck,
    faCircleXmark,
    faCircle,
    faRightToBracket,
    faFilterCircleXmark,
    faCrown,
    faFileCsv,
    faUserPlus,
    faSquareFull,
    faUsers,
    faInfo,
    faQuestion,
    faSliders,
    faSquarePlus,
    faUserMinus,
    faUser,
    faCheck,
    faXmark,
    faCircleExclamation,
    faCalendarDay,
    faCalendar,
    faPlus,
    faList,
    faMagnifyingGlassArrowRight,
    faPhone,
    faPhoneFlip,
    faArrowsRotate,
    faChartGantt,
    faChartColumn,
    faHandshake,
    faMapLocationDot,
    faTriangleExclamation,
    faSignsPost,
    faCity,
    faMapLocation,
    faFileExport,
    faExpand,
    faPaperPlane,
    faCalendarWeek,
    faReply,
    faTicket,
    faNetworkWired,
    faThumbsUp,
    faMoneyBill,
    faDollarSign,
    faLayerGroup,
    fa1,
    fa2,
    fa3,
    fa4,
    fa5,
    fa6,
    fa7,
    fa8,
    fa9,
    fa0,
    faLink,
    faCommentSms,
    faBuildingUser,
    faArrowUp,
    faArrowDown,
    faGear,
    faHeart,
    faComments,
    faBookOpen,
    faBook,
    faUserSlash,
    faBookSkull,
    faChevronLeft,
    faBarcode,
    faComment,
    faBug,
    faFileArrowUp,
    faFileArrowDown,
    faFileLines,
    faBarChart,
    faThumbtack,
} from "@fortawesome/free-solid-svg-icons";

library.add(
    faFileInvoiceDollar,
    faPercent,
    faCopy,
    faLink,
    faFloppyDisk,
    faSquareWhatsapp,
    faEye,
    faEyeSlash,
    faPen,
    faQrcode,
    faCircleInfo,
    faPeopleArrows,
    faFingerprint,
    faKey,
    faUnlock,
    faSpinner,
    faCalendarDays,
    faArrowsUpDown,
    faArrowUpWideShort,
    faArrowDownWideShort,
    faCalendarCheck,
    faCalendarXmark,
    faCircleCheck,
    faCircleXmark,
    faCircle,
    faRightToBracket,
    faFilterCircleXmark,
    faCrown,
    faQuestion,
    faSliders,
    faSquarePlus,
    faUserMinus,
    faUser,
    faCheck,
    faXmark,
    faCircleExclamation,
    faFileCsv,
    faUserPlus,
    faSquareFull,
    faWhatsapp,
    faUsers,
    faInfo,
    faPlus,
    faList,
    faMagnifyingGlassArrowRight,
    faCalendarDay,
    faCalendarWeek,
    faCalendar,
    faArrowsRotate,
    faChartGantt,
    faChartColumn,
    faHandshake,
    faMapLocationDot,
    faMapLocation,
    faFileExport,
    faCommentSms,
    faBuildingUser,
    faBuilding,
    faPhone,
    faPhoneFlip,
    faTriangleExclamation,
    faSignsPost,
    faCity,
    faExpand,
    faPaperPlane,
    faReply,
    faTicket,
    faNetworkWired,
    faThumbsUp,
    faMoneyBill,
    faDollarSign,
    faLayerGroup,
    fa1,
    fa2,
    fa3,
    fa4,
    fa5,
    fa6,
    fa7,
    fa8,
    fa9,
    fa0,
    faLink,
    faArrowUp,
    faArrowDown,
    faGear,
    faAddressBook,
    faClock,
    faHeart,
    faComments,
    faBookOpen,
    faBook,
    faUserSlash,
    faBookSkull,
    faChevronLeft,
    faBarcode,
    faComment,
    faBug,
    faFileArrowUp,
    faFileArrowDown,
    faFileLines,
    faBarChart,
    faThumbtack
);

export default FontAwesomeIcon;
