import { createStore } from "vuex";

import createPersistedState from "vuex-persistedstate";
import SecureLS from "secure-ls";

import providerRegister from "./providerRegister";
import communication from "./communication";
import segment from "./segment";
import origin from "./origin";
import prospectionProvider from "./prospectionProvider";
import comment from "./comment";

const ls = new SecureLS({ isCompression: false });

// Create a new store instance.
const store = createStore({
    modules: {
        providerRegister,
        communication,
        segment,
        origin,
        prospectionProvider,
        comment,
    },
    plugins: [
        createPersistedState({
            storage: {
                sessionStorage,
                getItem: (key) => ls.get(key),
                setItem: (key, value) => ls.set(key, value),
                removeItem: (key) => ls.remove(key),
            },
        }),
    ],
});

export default store;
